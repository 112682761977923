import React from "react";
import {
  easeInOut,
  m as motion,
  useReducedMotion,
  useScroll,
  useTransform,
} from "framer-motion";

const Snake: React.FC = () => {
  const { scrollY } = useScroll();

  const blur = useTransform(scrollY, [0, 1600], ["blur(0px)", "blur(80px)"], {
    ease: easeInOut,
    clamp: false,
  });

  const y = useTransform(() => scrollY.get() * -0.33);

  const prefersReducedMotion = useReducedMotion();

  return (
    <motion.div
      className="fixed inset-0 z-0 pointer-events-none select-none transform-gpu will-change-transform"
      role="presentation"
      style={{ y, filter: blur }}
    >
      <motion.div
        className="bg-snake transform-gpu scale-[2] top-[5%] absolute opacity-80 h-svh -left-1/2 -right-1/2 bg-[length:200vw_auto] bg-repeat-x bg-center -rotate-[20deg] md:top-[15%] md:scale-[1.75] xl:scale-150 2xl:scale-125 4xl:scale-100 dark:bg-snake-purple dark:opacity-20"
        initial={{ backgroundPosition: "0vw 50%" }}
        animate={{
          backgroundPosition: prefersReducedMotion ? "0vw 50%" : "200vw 50%",
        }}
        transition={{
          ease: "linear",
          duration: 180,
          repeat: Infinity,
          repeatType: "loop",
        }}
      />
    </motion.div>
  );
};

export default Snake;
