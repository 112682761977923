import React from "react";
import { m as motion, useReducedMotion } from "framer-motion";

type LogoProps = {
  className?: string;
};

const Logo: React.FC<LogoProps> = ({ ...props }) => {
  const prefersReducedMotion = useReducedMotion();

  return (
    <motion.svg
      height="40"
      viewBox="0 0 120 40"
      width="120"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="8"
      >
        <motion.line
          x1="4"
          y1="36"
          x2="4"
          y2="4"
          strokeDasharray={50}
          variants={{
            idle: { strokeDashoffset: 0 },
            draw: { strokeDashoffset: 100 },
          }}
          initial="idle"
          animate={prefersReducedMotion ? "idle" : "draw"}
          transition={{
            delay: 5.5,
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 15,
          }}
        />
        <motion.path
          d="M20,36 L20,12 C20,7.582 23.582,4 28,4 L28,4 C32.418,4 36,7.582 36,12 L36,28 C36,32.418 39.582,36 44,36 L44,36 C48.4,36 52,32.4 52,28 L52,12 C52,7.582 55.582,4 60,4 C64.418,4 68,7.582 68,12 L68,36"
          strokeDasharray={160}
          variants={{
            idle: { strokeDashoffset: 0 },
            draw: { strokeDashoffset: 320 },
          }}
          initial="idle"
          animate={prefersReducedMotion ? "idle" : "draw"}
          transition={{
            delay: 5,
            duration: 4,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 13,
          }}
        />
        <motion.path
          d="M116,4 L92,4 C87.582,4 84,7.582 84,12 L84,12 C84,16.418 87.582,20 92,20 L108,20 C112.418,20 116,23.582 116,28 L116,28 C116,32.418 112.418,36 108,36 L84,36"
          strokeDasharray={120}
          variants={{
            idle: { strokeDashoffset: 0 },
            draw: { strokeDashoffset: 240 },
          }}
          initial="idle"
          animate={prefersReducedMotion ? "idle" : "draw"}
          transition={{
            delay: 6,
            duration: 4,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 13,
          }}
        />
      </g>
    </motion.svg>
  );
};

export default Logo;
