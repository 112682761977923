import React from "react";
import Button from "@/components/button";
import type { LinkProps } from "@/components/link";
import Link from "@/components/link";
import Logo from "@/layouts/partials/logo";

const NavLink: React.FC<LinkProps> = ({ children = undefined, ...props }) => (
  <Link
    className="no-underline transition-colors duration-500 ease-in-out hover:text-white"
    {...props}
  >
    {children}
  </Link>
);

const Header: React.FC = () => (
  <nav className="fixed inset-x-0 z-50 overflow-hidden font-medium pointer-events-none text-lg/none top-md -mb-14">
    <div className="flex pb-20 py-px -mb-6 overflow-x-scroll px-sm pointer-events-auto before:content-[''] before:flex-1 after:content-[''] after:flex-1">
      <div className="flex items-center rounded-full shadow-xl shrink-0 gap-base border border-accent-500/50 bg-black/90 backdrop-blur-xl text-accent-500 p-xs pl-md min-h-12 md:pl-base">
        <div className="flex items-center shrink-0 mr-base md:mr-sm">
          <Link
            to="/"
            className="inline-block transition-colors duration-500 ease-in-out hover:text-white"
          >
            <Logo className="w-auto h-6" />
          </Link>
        </div>
        <ul className="items-center hidden gap-base md:flex">
          <li>
            <NavLink to="/#unternehmen">Unternehmen</NavLink>
          </li>
          <li>
            <NavLink to="/#karriere">Karriere</NavLink>
          </li>
        </ul>
        <Button to="/#bewerben">Jetzt bewerben</Button>
      </div>
    </div>
  </nav>
);

export default Header;
