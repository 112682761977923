import "@resources/styles/global.css";
import React, { useEffect } from "react";
import { domAnimation, LazyMotion } from "framer-motion";
import Snake from "@/components/snake";
import EhexBadge from "@/layouts/partials/ehex-badge";
import Footer from "@/layouts/partials/footer";
import Header from "@/layouts/partials/header";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  useEffect(() => {
    if (!document.head.querySelector("meta[name='theme-color']")) {
      const meta = document.createElement("meta");

      meta.setAttribute("name", "theme-color");

      document.head.appendChild(meta);
    }

    const updateThemeColor = (dark: boolean) =>
      document.head
        .querySelector("meta[name='theme-color']")
        ?.setAttribute("content", dark ? "#020202" : "#641AFF");

    const darkModePreference = window.matchMedia(
      "(prefers-color-scheme: dark)",
    );
    const listener = (e: MediaQueryListEvent) => updateThemeColor(e.matches);

    darkModePreference.addEventListener("change", listener);

    updateThemeColor(
      window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches,
    );

    return () => {
      darkModePreference.removeEventListener("change", listener);

      document.head.querySelector("meta[name='theme-color']")?.remove();
    };
  }, []);

  return (
    <LazyMotion features={domAnimation}>
      <Header />
      <main className="relative z-40">
        {children}
        <EhexBadge />
      </main>
      <Footer />
      <Snake />
    </LazyMotion>
  );
};

export default Layout;
